import React, { useState } from "react";
import {
  FaCoffee,
  FaCogs,
  FaHandshake,
  FaPhone,
  FaBolt,
  FaBook,
  FaMapMarkerAlt,
  FaTrophy,
  FaArrowUp,
  FaArrowDown,
  FaEye,
} from "react-icons/fa"; // React Icons kütüphanesinden ikonlar
import { IconType } from "react-icons"; // IconType için doğru içe aktarım
import "./Home.css"; // Özel CSS dosyası
import foto from "../Image/pexels-cottonbro-studio-3737655.jpg";
import foto1 from "../Image/kahve-kreması.png";
import foto2 from "../Image/turkkahve.png";
import foto3 from "../Image/cekirdekkhave.png";
import foto4 from "../Image/granul-min.jpg";
import foto5 from "../Image/sicakcikolata.png";
import foto6 from "../Image/kalsik.png";
import foto7 from "../Image/filtrekahvebg.png";
import Kahveler from "../Image/kahveler.jpeg";
import Kahveler1 from "../Image/ckrdkkahve.jpeg";
import Carousel from "./Carousel/Carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"; // Doğru paketten içe aktarılıyor
import { useNavigate } from "react-router-dom";
import urun from "../Image/PNG11.png";
import urun1 from "../Image/PNG22.png";
import urun2 from "../Image/PNG33.png";
import urun3 from "../Image/PNG44.png";
import urun4 from "../Image/PNG55.png";
import urun5 from "../Image/PNG66.png";
import urun6 from "../Image/PNG77.png";
interface InfoCardProps {
  IconComponent: IconType;
  number: string;
  text: string;
}
interface Item {
  id: number;
  imageUrl: string;
  name: string;
}

interface YourComponentProps {
  items: Item[];
}

const YourComponent: React.FC<YourComponentProps> = ({ items }) => {
  return (
    <div className="container1">
      {items.map((item) => (
        <div key={item.id} className={`card-${item.id} card-div`}>
          <div className="like-icon-div">
            <label
              htmlFor={`card-${item.id}-like`}
              className="like-icon-div-child"
            >
              <input type="checkbox" id={`card-${item.id}-like`} />
            </label>
          </div>
          <div className={`${item.name.toLowerCase()}-img-div img-div`}>
            <img
              src={item.imageUrl}
              alt={`${item.name.toLowerCase()}-figurine`}
            />
          </div>
          <div className="text-container">
            <h2 className="item-name">{item.name}</h2>
            {/* <p className="date"> Departure date: {item.departureDate} </p> */}
            <div className="pricing-and-cart">
              <div className="pricing">
                {/* <p className="previous-price">${item.previousPrice}</p>
                <p className="current-price">${item.currentPrice}</p> */}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
const InfoCard: React.FC<InfoCardProps> = ({ IconComponent, number, text }) => {
  return (
    <div className="card text-center bg-transparent text-white">
      <div className="card-body d-flex  align-items-center">
        <div className="bg-altin p-4 rounded-3  d-flex">
          <IconComponent size={40} />
        </div>
        <div className="ms-5">
          <h2 className="card-title w-100">{number}</h2>
          <p className="card-text w-100">{text}</p>
        </div>
      </div>
    </div>
  );
};
function Home() {
  const navigate = useNavigate();

  const [items, setItems] = useState<Item[]>([
    {
      id: 1,
      imageUrl: urun,
      name: "KAHVE KREMASI",
    },
    {
      id: 2,
      imageUrl: urun1,
      name: "TÜRK KAHVESİ",
    },
    {
      id: 3,
      imageUrl: urun2,
      name: "ÇEKİRDEK KAHVE",
    },
    {
      id: 4,
      imageUrl: urun3,
      name: "GOLD KAHVE",
    },
    // Add more items as needed
  ]);
  const images = [
    {
      url: foto1,
      url1: urun,
      title: "KAHVE KREMASI",
      title1: "Kahve kreması nedir ve ne için kullanılır?",
      title2:
        "Kahve kreması, kahvenize zengin ve kremsi bir tat katmak için kullanılan bir üründür. Süt veya krema bazlı olabilir ve kahvenin lezzetini yumuşatır.",
      isActive: true,
    },
    {
      url: foto2,
      url1: urun1,
      title: "TÜRK KAHVESİ",
      title1: "Türk kahvesi özel yapılışına nasıl sahip olur?",
      title2: "Türk kahvesi, ince öğütülmüş kahve çekirdekleri ve suyun cezvede yavaşça pişirilmesiyle yapılır. Bu yöntem, kahvenin yoğun ve köpüklü olmasını sağlar.",
      isActive: false,
    },
    {
      url: foto3,
      url1: urun2,
      title: "ÇEKİRDEK KAHVE",
      title1: "Çekirdek kahve ile öğütülmüş kahve arasındaki fark nedir?",
      title2: "Çekirdek kahve, tazeliğini daha uzun süre korur ve öğütüldüğünde daha taze bir tat sunar. Öğütülmüş kahve ise kullanımı daha pratiktir ama hızlıca aromasını kaybedebilir.",
      isActive: false,
    },
    {
      url: foto4,
      url1: urun3,
      title: "GOLD KAHVE",
      title1: "Gold kahve nedir ve diğer kahvelerden farkı nedir?",
      title2: "Gold kahve, yüksek kaliteli kahve çekirdeklerinden yapılan premium bir kahve türüdür. Zengin aroma ve lezzeti ile diğer kahvelerden ayrılır.",
      isActive: false,
    },
    {
      url: foto5,
      url1: urun4,
      title: "SICAK ÇİKOLATA",
      title1: "Sıcak çikolata için hangi çikolata türü kullanılır?",
      title2: "Genellikle yüksek kakao içerikli bitter veya yarı tatlı çikolata kullanılır, bu da sıcak çikolataya zengin bir tat verir.",
      isActive: false,
    },
    {
      url: foto6,
      url1: urun5,
      title: "KLASİK KAHVE",
      title1: "Klasik kahve ile espresso arasındaki temel fark nedir?",
      title2: "Klasik kahve, genellikle daha uzun sürede ve daha az basınçla hazırlanırken, espresso kısa sürede yüksek basınç altında yapılır. Bu, espresso'nun daha yoğun ve koyu olmasını sağlar.",
      isActive: false,
    },
    {
      url: foto7,
      url1: urun6,
      title: "Filtre KAHVE",
      title1: "Filtre kahve nasıl yapılır?",
      title2: "Filtre kahve, kahve çekirdeklerinin öğütülmesi ve sıcak suyun üzerinden geçirilmesiyle yapılır. Bu yöntem, kahvenin aromasını ve lezzetini korumasını sağlar.",
      isActive: false,
    },
  ];
  const goToAboutPage = () => {
    navigate("/Kurumsal");
  };
  const goTourun = () => {
    navigate("/urunler");
  };
  return (
    <>
      <div className="container-fluid " style={{ height: "100vh " }}>
        <div className="row">
          <div className="col-md-12 m-0 p-0">
            <Carousel images={images} />
          </div>
        </div>
      </div>
      <div className="container-fluid p-0 bg-altin">
        <div className="row m-0">
          {/* Kahve Makineleri */}
          <div className="col-md-4 p-4  d-flex align-items-center justify-content-center text-white">
            <div className="sbg d-flex p-4 rounded-2">
              <div className="icon-wrapper">
                <FaCoffee size={30} color="#DAA520 " />
              </div>
              <p className="m-0 text-center">
                Endüstriyel kahve makineleri alanında öncü, geniş ürün yelpazesi
                ve kaliteli servis anlayışı.
              </p>
            </div>
          </div>

          {/* Teknik Servis */}
          <div className="col-md-4 p-4  d-flex align-items-center justify-content-center text-white">
            <div className="sbg d-flex p-4 rounded-2">
              <div className="icon-wrapper">
                <FaCogs size={30} color="#DAA520 " />
              </div>
              <p className="m-0 text-center">
                Hızlı ve güvenilir teknik servis desteği ile sürekli müşteri
                memnuniyeti odaklı hizmet.
              </p>
            </div>
          </div>

          {/* Kurumsal Bilgiler */}
          <div className="col-md-4  p-4 d-flex align-items-center justify-content-center text-white">
            <div className="sbg d-flex p-4 rounded-2">
              <div className="icon-wrapper">
                <FaHandshake size={30} color="#DAA520 " />
              </div>
              <p className="m-0 text-center">
                Yenilikçi ve sürdürülebilir iş modelleri ile uzun vadeli ve
                güvenilir iş ortaklıkları kuruyoruz.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row ">
          <div className="col-lg-6 p-4 d-flex flex-column">
            <h2 className="animated-text text-white text-center">Hakkımızda</h2>
            <div className="d-flex justify-content-center ">
              <div className="custom-h1"></div>
            </div>
            <h3 className="text-uppercase text-center text-warning font-weight-bold mb-3">
              Kahve Tutkunuzu Zİrveye Taşıyoruz !
            </h3>
            <p className="animated-text text-white text-center ">
              Kahve Tutkunuzun Merkezi - Biz, kahve severlerin beklentilerini en
              üst düzeyde karşılamak için çekirdek kahve ve hazır kahve
              seçenekleri sunan bir firmayız. <br />
              <br /> Müşterilerimize en iyi kahve deneyimini yaşatmak için
              firmalara özel konsinye kahve makineleri sağlıyoruz. Kalitemizle
              fark yaratıyor, lezzetimizle hayatınıza değer katıyoruz.
            </p>
            <div className=" d-flex justify-content-end align-items-center h-100">
              <button className="more-button" onClick={goToAboutPage}>
                Devamını Oku
                <FontAwesomeIcon icon={faArrowRight} className="button-icon" />
              </button>
            </div>
          </div>
          <div className="col-lg-6 p-4">
            <img
              src={Kahveler}
              alt="Animasyonlu Resim"
              className="animated-image rounded"
            />
          </div>
        </div>
      </div>
      <div className=" container-fluid banner-container">
        <div className="text-overlay">
          <h1 className="text-warning">Senin Tarzın Hangisi?</h1>
          <p className="fs-5">
            Endüstriyel kahve makineleri konusundaki uzmanlığımızla, kahve
            tutkunlarına özel bir deneyim sunuyoruz. İkonik Coffeedean damlası
            ile her fincanımız, sizin tarzınızı yansıtıyor.
          </p>
        </div>
      </div>
      <div className="container-fluid bg-altin">
        <div className="row ">
          <div className="col-lg-6 p-4">
            <img
              src={Kahveler1}
              alt="Animasyonlu Resim"
              className="animated-image rounded"
            />
          </div>
          <div className="col-lg-6 p-4 d-flex flex-column">
            <h3 className="animated-text sbg text-center  text-warning p-2 rounded">
              Neden CoffeeDean ile Konsinye Kahve Satışını Tercih Etmelisiniz?
            </h3>

            <p className="animated-text text-white mt-4">
              <span className="fs-3 text-siyah">Düşük Başlangıç Maliyeti:</span>
              CoffeeDean olarak, işletmelere konsinye yoluyla kahve makineleri
              ve ürünler sunuyoruz. Bu sayede, başlangıç maliyetlerini düşürüyor
              ve yüksek kaliteli ekipmanların büyük yatırım yapmadan
              kullanılmasını sağlıyoruz.
            </p>
            <p className="animated-text text-white mt-4">
              <span className="fs-3 text-siyah">
                Yüksek Kalite Standartları:
              </span>
              CoffeeDean, kaliteli kahve ve kahve makineleri sunar. Bu,
              müşterilere sunulan kahvenin kalitesini garantiler ve işletmelerin
              müşteri memnuniyetini artırmasına yardımcı olur.
            </p>
            <p className="animated-text text-white mt-4">
              <span className="fs-3 text-siyah">
                7/24 Teknik Destek ve Bakım{" "}
              </span>
              CoffeeDean olarak, konsinye kahve makinelerimize 7/24 teknik
              destek ve bakım sağlıyoruz. Bu hizmet, herhangi bir teknik sorunda
              hızlı çözüm sunarak işletmelerin kesintisiz çalışmasını garanti
              eder ve müşteri memnuniyetini artırır.
            </p>
            <div
              className="h-100 d-flex align-items-center phot justify-content-end pointer"
              onClick={() => {
                window.open("https://wa.me/905514022172", "_blank");
              }}
            >
              <div className="icon-wrapper">
                <FaPhone size={25} color="#FFD700" />
              </div>
              <div className="d-flex flex-column ">
                <p className="text-siyah m-0 p-0  align-items-center">
                  bizimle iletiişime geçin
                </p>
                <p className="text-white m-0 p-0  align-items-center">
                  0 551 402 21 72
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="container-fluid p-0">
        <div className="info-container">
          <div className="row">
            <div className="col-md-3 mb-3">
              <InfoCard
                IconComponent={FaBolt}
                number="10"
                text="YILLIK TECRÜBE"
              />
            </div>
            <div className="col-md-3 mb-3">
              <InfoCard
                IconComponent={FaBook}
                number="2500"
                text="MUTLU MÜŞTERILER"
              />
            </div>
            <div className="col-md-3 mb-3">
              <InfoCard
                IconComponent={FaMapMarkerAlt}
                number="15"
                text="3 BÖLGE"
              />
            </div>
            <div className="col-md-3 mb-3">
              <InfoCard
                IconComponent={FaTrophy}
                number="10"
                text="BAŞARI ÖDÜLÜ"
              />
            </div>
          </div>
        </div>
      </div> */}
      <div className="container-fluid">
        <div className="row">
          <h2 className="text-center mt-4 text-white">Ürünlerimiz</h2>
          <div className="custom-h1"></div>
          <YourComponent items={items} />
          <div className="col-12 mb-5 d-flex justify-content-center">
            <button className="animated-button1" onClick={goTourun}>
              <FaEye /> Tüm Ürünlerimizi İnceleyebilirsiniz. Tümünü Görüntüle
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
