import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Menu from './Menu/Menu';
import Footer from './Footer/Footer';
import Home from './Pages/Home';
import About from './Pages/About';
import Services from './Pages/Services';
import Contact from './Pages/Contact';

function App() {
  return (
    <Router>
      <div className="" style={{background:''}}>
        <Menu />
        <div className="content">
          <Routes>
            <Route path="/" element={<Navigate replace to="/home" />} /> {/* Kök dizine erişildiğinde /home'a yönlendir */}
            <Route path="/home" element={<Home />} />
            <Route path="/Kurumsal" element={<About />} />
            <Route path="/urunler" element={<Services />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/*" element={<Navigate replace to="/home" />} /> {/* Geçersiz bir yol için /home'a yönlendir */}
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;


