import React from "react";
import "./About.css"; // Özel CSS stilinizi burada import edin
import { FaCoffee, FaSeedling } from "react-icons/fa"; // İkon kütüphanesinden ikonları import edin
import { GiCheckMark } from "react-icons/gi";
import phone from "../Image/kahveler.jpeg"; // Resimlerinizi burada import edin
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Vm } from "./Vm";

const About: React.FC = () => {
  return (
    <>
      <div className="container mb-4 ">
        <div className="row mb-4">
          <h2 className="animated-text text-white text-center mt-5">
            Hakkımızda
          </h2>
          <div className="d-flex justify-content-center">
            <div className="custom-h1"></div>
          </div>
        </div>
      </div>
      <div className="container-fluid p-0 m-0 about-page ">
        <div className="row m-0 p-5 align-items-center text-white  bg-coffee-beans kahvebg">
          {/* <div className="col-lg-6 p-5">
            <FaCoffee size={50} className="icon" /> 
            <h2>Misyonumuz</h2>
            <p>
              Müşteri memnuniyetini önceliklendirerek, yüksek kalitede, sağlıklı
              ve lezzetli kahve çeşitleri sunmak.
            </p>
          </div>
          <div className="col-lg-6 p-5">
            <FaSeedling size={50} className="icon" />
            <h2>Vizyonumuz</h2>
            <p>
              Kahve sektöründe yenilikçi ve sürdürülebilir uygulamalar ile öncü
              bir marka olmak.
            </p>
          </div>
          <div className="col-12 p-5 border-top-dashed">
            <div className="d-flex align-items-center justify-content-center flex-column">
              <GiCheckMark size={50} className="icon" />
              <h2>Coffeedean KALİTE POLİTİKASI</h2>
              <p>
                Sürekli iyileştirme ve mükemmellik arayışı içinde, müşteri
                beklentilerini aşan, yüksek standartlarda kahve üretmek ve
                sunmak.
              </p>
            </div>
          </div>*/}
          <Vm
            icon={<FaCoffee />}
            title="Misyonumuz"
            description="              Müşteri memnuniyetini önceliklendirerek, yüksek kalitede, sağlıklı
              ve lezzetli kahve çeşitleri sunmak."
            backgroundColor="rgba(0, 195, 255, 0.15)" // Arka plan rengi
            iconColor="#00c3ff" // İkon rengi
          />
          <Vm
            icon={<FaSeedling />}
            title="Vizyonumuz"
            description="              Kahve sektöründe yenilikçi ve sürdürülebilir uygulamalar ile öncü
              bir marka olmak."
            backgroundColor="rgba(1,96,231,0.152)" // Arka plan rengi
            iconColor="#0160e7" // İkon rengi
          />
          <Vm
            icon={<GiCheckMark />}
            title="Coffeedean KALİTE POLİTİKASI"
            description="Sürekli iyileştirme ve mükemmellik arayışı içinde, müşteri
              beklentilerini aşan, yüksek standartlarda kahve üretmek ve
              sunmak."
            backgroundColor="rgba(254,76,28,0.154)" // Arka plan rengi
            iconColor="#fe4c1c" // İkon rengi
          />
        </div>
      </div>
      <div className="container-fluid bg-altin p-3">
        <div className="row  ">
          <div className="col-lg-6 p-4 d-flex flex-column align-items-center">
            <div className="faq-container">
              <div className="faq-item className">
                <h3 className="faq-question text-siyah text-center">
                  Coffeedean olarak kimiz?
                </h3>
                <p className="faq-answer aksiyon-gri text-center">
                  Biz, Coffeedean olarak kahve sektöründe hizmet veren bir firma
                  olarak, müşterilerimize en kaliteli kahve makineleri ve kahve
                  çeşitlerini sunuyoruz. Amacımız, müşterilerimize eşsiz bir
                  kahve deneyimi sağlamaktır.
                </p>
              </div>
              <div className="faq-item ">
                <h3 className="faq-question text-siyah text-center">
                  Hangi kahve makineleri ve ürünleri sunuyoruz?
                </h3>
                <p className="faq-answer aksiyon-gri text-center">
                  Müşterilerimizin farklı ihtiyaçlarına cevap verebilmek için
                  geniş bir kahve makinesi yelpazesi sunuyoruz. Ayrıca,
                  espresso, filtre kahve, Türk kahvesi gibi çeşitli kahve
                  türlerini de toptan olarak temin ediyoruz.
                </p>
              </div>
              <div className="faq-item ">
                <h3 className="faq-question text-siyah text-center">
                  Müşteriler neden Coffeedean'ı tercih etmeli?
                </h3>
                <p className="faq-answer aksiyon-gri text-center">
                  Müşteri memnuniyetini her şeyden önce tutuyoruz. Kaliteli
                  ürünlerimiz, rekabetçi fiyatlarımız ve esnek konsinye makine
                  dağıtım sistemimizle her zaman müşterilerimizin yanındayız.
                  Ayrıca, güçlü lojistik ağımızla hızlı ve güvenilir teslimat
                  sağlıyoruz.
                </p>
              </div>
              {/* <div className="faq-item ">
                <h3 className="faq-question text-siyah text-center">
                  Konsinye makine dağıtım sistemimiz nasıl çalışıyor?
                </h3>
                <p className="faq-answer aksiyon-gri text-center">
                  Bizimle çalışan işletmelere, önceden ödeme yapmadan kahve
                  makinelerini kullanma imkanı sunuyoruz. Makinelerimiz,
                  kullanım ve satış performansına göre ücretlendirilir, böylece
                  işletmeler hem maliyetlerini düşürür hem de kaliteli
                  makinelerle hizmet sunarlar.
                </p>
              </div> */}
            </div>
          </div>
          <div className="col-lg-6 d-flex align-items-center">
            <img
              src={phone}
              alt="Animasyonlu Resim"
              className="animated-image rounded "
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
