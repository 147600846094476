import React, { useEffect, useState } from "react";
import CarouselItem from "./CarouselItem";

interface CarouselProps {
  images: { url: string;url1: string;  title: string, title1: string , title2: string  }[];
}

const Carousel: React.FC<CarouselProps> = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const transitionTime = 5000; // 5 seconds

  const goToPrevious = () => {
    const newIndex = currentIndex === 0 ? images.length - 1 : currentIndex - 1;
    console.log("Previous Index:", newIndex); // Log ekleyin
    setCurrentIndex(newIndex);
  };
  
  const goToNext = () => {
    const newIndex = currentIndex === images.length - 1 ? 0 : currentIndex + 1;
    console.log("Next Index:", newIndex); // Log ekleyin
    setCurrentIndex(newIndex);
  };
  
  useEffect(() => {
    console.log("Current Index in Effect:", currentIndex); // Log ekleyin
    const timer = setInterval(goToNext, transitionTime);
    return () => clearInterval(timer);
  }, [currentIndex]);
  

  return (
    <div className="carousel-container1 h-100">
      <button
        className="button1 d-flex align-items-center rounded justify-content-center"
        onClick={goToPrevious}
      >
        {"<"}
      </button>

      <div className="carousel-slide">
        {images.map((image, index) => (
          <CarouselItem 
            key={index}
            imageUrl={image.url} 
            imageUrl1={image.url1} 
            title={image.title} 
            title1={image.title1} 
            title2={image.title2} 
            isActive={currentIndex === index} // Sadece aktif olan slayt için true olacak
          />
        ))}
      </div>

      <button
        className="button1 d-flex align-items-center rounded justify-content-center"
        onClick={goToNext}
      >
        {">"}
      </button>
    </div>
  );
};

export default Carousel;
