import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom"; // useLocation hook'u eklendi
import { FaEnvelope, FaHome, FaInfo,FaCoffee } from "react-icons/fa";
import "./Menu.css";
import logoImage from "../Image/logo.png";

const Menu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation(); // Geçerli URL yolu

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Aktif menü öğesini kontrol etme fonksiyonu
  const isActive = (path: any) => {
    return location.pathname === path;
  };
  

  return (
    <div className="">
      <header className="menu-header">
        <div className="logo">
          <img src={logoImage} alt="Logo" />
        </div>
        <nav className={`nav-links ${isOpen ? "open" : ""}`}>
          <Link className={isActive("/home") ? "active" : ""} to="/home">
            <FaHome /> Home
          </Link>
          <Link className={isActive("/Kurumsal") ? "active" : ""} to="/Kurumsal">
            <FaInfo /> Kurumsal
          </Link>
          <Link
            className={isActive("/urunler") ? "active" : ""}
            to="/urunler"
          >
            <FaCoffee /> Ürünler ve Makineler
          </Link>
          <Link className={isActive("/contact") ? "active" : ""} to="/contact">
          <FaEnvelope /> İletişim
          </Link>
        </nav>
        <div
          className={`hamburger-menu ${isOpen ? "active" : ""}`}
          onClick={toggleMenu}
        >
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </header>
    </div>
  );
};

export default Menu;
