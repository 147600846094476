import React from "react";

interface CarouselItemProps {
  imageUrl: string;
  imageUrl1: string;
  title: string;
  title1: string;
  title2: string;
  isActive: boolean;
}

const CarouselItem: React.FC<CarouselItemProps> = ({
  imageUrl,
  imageUrl1,
  title,
  title1,
  title2,
  isActive,
}) => {
  console.log("Is Active:", isActive, "Title:", title); // Log ekleyin
  return (
    <div
      className={`carousel-item1 ${isActive ? "active-slide" : ""}`}
      style={{ backgroundImage: `url(${imageUrl})` }}
    >
      <div className="dark-overlay"></div> {/* Dark Overlay */}
      <div className="container">
        <div className="row d-flex text-content">
          <div className={`col-md-6 resim ${isActive ? "active-slide" : ""}`}>
            <img src={imageUrl1} alt="Açıklama" />
          </div>

          <div className="col-md-6 text-content p-5">
            {" "}
            {/* Text Content */}
            <h1 className="text-center">{title}</h1>
            <p className="p1 text-center">{title1}</p>
            <p className="p2 text-center">{title2}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarouselItem;
