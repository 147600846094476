// src/components/ProductCard.tsx
import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import urun from "../Image/PNG11.png";
import urun1 from "../Image/PNG22.png";
import urun2 from "../Image/PNG33.png";
import urun3 from "../Image/PNG44.png";
import urun4 from "../Image/PNG55.png";
import urun5 from "../Image/PNG66.png";


import makine from "../Image/kef-filtro-flt120-filtre-kahve-makinesi-144-fincan-saat-kapasiteli-siyah-renk-tek-potlu-2400-w-ekofan-com-tr-Ekofan-tinified.jpg";
import makine1 from "../Image/necta-korinto-prime-esv3r-espresso-kahve-makinesi-tam-otomatik-espresso-kahve-makineleri-necta-25372-14-O.jpg";
import makine2 from "../Image/novo-filtre-kahve-makinesi-filtre-kahve-makineleri-bravilor-bonamat-53109-93-O.jpg";
import makine3 from "../Image/saeco-perfetta-espresso-kahve-makinesi-tall-cup-2-gruplu-beyaz-espresso-kahve-makineleri-saeco-48165-17-O.jpg";
import makine4 from "../Image/saeco-perfetta-espresso-kahve-makinesi-tall-cup-2-gruplu-siyah-espresso-kahve-makineleri-saeco-48167-17-O.jpg";
import makine5 from "../Image/saeco-phedra-evo-9-gr-ri-cappuccino-kahve-makinesi-espresso-kahve-makineleri-saeco-60433-17-O.jpg";
import makine6 from "../Image/tranquilo-tron-dijital-on-demand-kahve-degirmeni-cunill-63920-94-O.jpg";
import makine7 from "../Image/bizim.jpeg";

interface YourComponentProps {
  items: Item[];
}
interface Item {
  id: number;
  imageUrl: string;
  name: string;
}

const YourComponent: React.FC<YourComponentProps> = ({ items }) => {
  return (
    <div className="container1">
      {items.map((item, index) => (
        <div
          key={item.id}
          className={`card-${item.id} card-div`}
          style={{
            animationDelay: `${0.2 * (index + 1)}s`,
          }}
        >
          <div className="like-icon-div">
            <label
              htmlFor={`card-${item.id}-like`}
              className="like-icon-div-child"
            >
              <input type="checkbox" id={`card-${item.id}-like`} />
            </label>
          </div>
          <div className={`${item.name.toLowerCase()}-img-div img-div`}>
            <img
              src={item.imageUrl}
              alt={`${item.name.toLowerCase()}-figurine`}
              className="img-card1"
            />
          </div>
          <div className="text-container">
            <h2 className="item-name">{item.name}</h2>
            {/* <p className="date"> Departure date: {item.departureDate} </p> */}
            <div className="pricing-and-cart">
              <div className="pricing">
                {/* <p className="previous-price">${item.previousPrice}</p>
                <p className="current-price">${item.currentPrice}</p> */}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const Services = () => {
  const [activeTab, setActiveTab] = useState("products");
  const mockProducts = [
    { id: 1, imageUrl: urun, name: "KAHVE KREMASI" },
    { id: 1, imageUrl: urun1, name: "TÜRK KAHVESİ" },
    { id: 2, imageUrl: urun2, name: "ÇEKİRDEK KAHVE" },
    { id: 3, imageUrl: urun3, name: "GOLD KAHVE" },
    { id: 4, imageUrl: urun4, name: "SICAK ÇİKOLATA" },
    { id: 5, imageUrl: urun5, name: "KLASİK KAHVE" },
  ];
  const mockMachines = [
    { id: 1, imageUrl: makine7, name: "GİTES ANGEL" },
    { id: 2, imageUrl: makine4, name: "Saeco Perfetta Yarı Otomatik Tall Cup Espresso Kahve Makinesi" },
    { id: 3, imageUrl: makine2, name: "Bravilor Bonamat Novo Filtre Kahve Makinesi" },
    { id: 4, imageUrl: makine3, name: "Saeco Perfetta Yarı Otomatik Tall Cup Espresso Kahve Makinesi" },
    { id: 5, imageUrl: makine, name: "KEF Filtro FLT120 Filtre Kahve Makinesi" },
    { id: 6, imageUrl: makine6, name: "Cunill Tranquilo Tron Dijital On Demand Kahve Değirmeni" },
    { id: 7, imageUrl: makine5, name: "Saeco Phedra Evo 9 Cappuccino Kahve Makinesi" },
    { id: 7, imageUrl: makine1, name: "Necta Korinto Prime ESV3R Tam Otomatik Espresso Kahve Makinesi" },
  ];
  const renderContent = () => {
    let contentKey = activeTab; // Anahtar olarak aktif sekmenin adını kullanın
    switch (activeTab) {
      case "products":
        return <YourComponent key={contentKey} items={mockProducts} />;
      case "machines":
        return <YourComponent key={contentKey} items={mockMachines} />;
      default:
        return <div>Content not found</div>;
    }
  };
  return (
    <div className="container">
      <div className="row">
        <h2 className="text-center mt-4 text-white"> Ürünler ve Makineler</h2>
        <div className="custom-h1"></div>
        <div className="sidebar-menu1 mt-4">
          <button
            onClick={() => setActiveTab("products")}
            className={activeTab === "products" ? "active" : ""}
          >
            Ürünlerimiz
          </button>
          <button
            onClick={() => setActiveTab("machines")}
            className={activeTab === "machines" ? "active" : ""}
          >
            Makinelerimiz
          </button>
        </div>
        <div className="content1">{renderContent()}</div>
      </div>
    </div>
  );
};

export default Services;
